export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDIY3x7vPVcVzqcYV547nEefCAD4JI40tE',
    authDomain: 'ecdi-3ce42.firebaseapp.com',
    projectId: 'ecdi-3ce42',
    storageBucket: 'ecdi-3ce42.firebasestorage.app',
    messagingSenderId: '229677276731',
    appId: '1:229677276731:web:83f627227ba0a1598a061e',
    measurementId: 'G-9H8HTL9ZT0',
  },
};
