import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { CoreService } from 'src/app/services/core.service';
import { AppSettings } from 'src/app/config';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { navItems } from './vertical/sidebar/sidebar-data';
import { NavService } from '../../services/nav.service';
import { AppNavItemComponent } from './vertical/sidebar/nav-item/nav-item.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './vertical/sidebar/sidebar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { HeaderComponent } from './vertical/header/header.component';
//import { AppBreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './shared/customizer/customizer.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/pages/dashboard/datos';
import { SuplantacionService } from 'src/app/services/suplantacion.service';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';
const BELOWMONITOR = 'screen and (max-width: 1023px)';

@Component({
  selector: 'app-full',
  standalone: true,
  imports: [
    RouterModule,
    AppNavItemComponent,
    MaterialModule,
    CommonModule,
    SidebarComponent,
    NgScrollbarModule,
    TablerIconsModule,
    HeaderComponent,
    //AppBreadcrumbComponent,
    CustomizerComponent,
  ],
  templateUrl: './full.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class FullComponent implements OnInit {
  perfilUsuario: number[] = [2];
  TieneCarteras: boolean = false;
  NombreCompleto: string = '';
  Perfil: string = 'Inversor';
  navItems = navItems;
  //navItems = navItems.filter(item => item.show === 0);

  @ViewChild('leftsidenav')
  public sidenav: MatSidenav;
  resView = false;
  @ViewChild('content', { static: true }) content!: MatSidenavContent;
  //get options from service
  options = this.settings.getOptions();
  private layoutChangesSubscription = Subscription.EMPTY;
  private isMobileScreen = false;
  private isContentWidthFixed = true;
  private isCollapsedWidthFixed = false;
  private htmlElement!: HTMLHtmlElement;
  private _Usuario: Usuario;
  private _TieneRenovacion: boolean = false;
  public isSuplantando = false;
  public usuarios: Usuario[] = [];

  get isOver(): boolean {
    return this.isMobileScreen;
  }

  get isTablet(): boolean {
    return this.resView;
  }

  constructor(private settings: CoreService, private suplantacionService: SuplantacionService, private router: Router, private cdr: ChangeDetectorRef, private breakpointObserver: BreakpointObserver, private usuarioService: UsuarioService) {
    this.htmlElement = document.querySelector('html')!;
    this.layoutChangesSubscription = this.breakpointObserver.observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW, BELOWMONITOR]).subscribe((state) => {
      // SidenavOpened must be reset true when layout changes
      this.options.sidenavOpened = true;
      this.isMobileScreen = state.breakpoints[BELOWMONITOR];
      if (this.options.sidenavCollapsed == false) {
        this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
      }
      this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
      this.resView = state.breakpoints[BELOWMONITOR];
    });

    // Initialize project theme with options
    this.receiveOptions(this.options);

    // This is for scroll to top
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e) => {
      this.content.scrollTo({ top: 0 });
    });
  }

  ngOnInit(): void {
    this.suplantacionService.suplantando$.subscribe((isSuplantando) => {
      this.isSuplantando = isSuplantando;
      this.cdr.detectChanges();
      this.cargarContenido();
    });
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

  ngAfterContentInit() {
    this.cargarContenido();
  }

  cargarContenido() {
    var usuariosConCarteraCortoMayor50000: number = 0;
    var usuariosSinCarteras: number = 0;

    const user = this.usuarioService.getUser();
    if (user) {
      this._Usuario = user;
      this.NombreCompleto = `${user.nombre} ${user.apellidos}`;
      const roles = user.roles.map((role) => role.rol);
      const highestRole = Math.min(...roles);
      switch (highestRole) {
        case 1:
          this.Perfil = 'Admin';
          break;
        case 2:
          this.Perfil = 'Gestor';
          break;
        case 3:
          this.Perfil = 'Inversor';
          break;
        default:
          this.Perfil = 'Unknown';
      }
    }

    if (this._Usuario && this._Usuario.roles) {
      this.perfilUsuario = this._Usuario.roles.map((role) => role.rol);
      const today = new Date();

      this._TieneRenovacion = this._Usuario.carteras.some((cartera) => {
        const fechaRenovacion = new Date(cartera.fechaRenovacion.fecha);
        const fechaRenovacionMinusFiveDays = new Date(fechaRenovacion);
        //Si volem retrasar la renovación 5 días DESCOMENTAR
        //fechaRenovacion.setDate(fechaRenovacion.getDate() + 6);
        fechaRenovacionMinusFiveDays.setDate(fechaRenovacionMinusFiveDays.getDate() - 5);
        console.log(fechaRenovacionMinusFiveDays, today, fechaRenovacion);
        return fechaRenovacionMinusFiveDays <= today && today <= fechaRenovacion;
      });
    }

    this.usuarioService.obtenerUsuarios().subscribe((data) => {
      this.usuarios = JSON.parse(data) as Usuario[];

      usuariosSinCarteras = this.usuarios.filter((usuario) => usuario.carteras.length === 0).length;

      usuariosConCarteraCortoMayor50000 = this.usuarios.filter((usuario) => {
        const carterasCorto = usuario.carteras.filter((cartera) => cartera.tipo == 1 && cartera.cantidadReal >= 50000);
        return carterasCorto.length > 0 && usuario.carteras.length === 1;
      }).length;

      this.navItems = navItems
        .map((item) => {
          if (item.route === '/renovacion') {
            //DESCOMENTAR
            return { ...item, disabled: !this._TieneRenovacion };
            //return { ...item, disabled: false };
          }
          if (item.route === '/usuarios/crear') {
            if (usuariosSinCarteras + usuariosConCarteraCortoMayor50000 > 0) return { ...item, chip: true, chipClass: 'bg-secondary text-white', chipContent: (usuariosSinCarteras + usuariosConCarteraCortoMayor50000).toString() };
          }
          return item;
        })
        .filter((item) => this.perfilUsuario.includes(item.show ?? -1));
    });
  }

  toggleCollapsed() {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => this.settings.setOptions(this.options), timer);
  }

  onSidenavClosedStart() {
    this.isContentWidthFixed = false;
  }

  onSidenavOpenedChange(isOpened: boolean) {
    this.isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
    this.settings.setOptions(this.options);
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }
}
