import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, firstValueFrom, from, lastValueFrom, map, mergeMap, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { Carteras, FirebaseAuthRefreshResponse, FirebaseAuthResponse, FirebaseAuthUsuarioResponse, Resultado, TipoCartera, Usuario, UsuarioRequest } from '../pages/dashboard/datos';
import { Router } from '@angular/router';

import { firebaseApp } from 'src/main'; // Importar la instancia de Firebase
import { getAuth, Auth, sendPasswordResetEmail } from 'firebase/auth';
import { SuplantacionService } from './suplantacion.service';
import { Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  //DESCOMENTAR
  private apiUrl = 'https://ecdi.azurewebsites.net/';
  //private apiUrl = 'http://localhost:5118/';
  private auth: Auth;
  isRenewingToken = false;
  private tokenRenewalSubject: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private router: Router, @Inject(SuplantacionService) private suplantacionService: SuplantacionService) {
    this.auth = getAuth(firebaseApp);
  }

  obtenerUsuarios(): Observable<string> {
    return this.http.get<string>(this.apiUrl + 'usuarios', { responseType: 'text' as 'json' });
  }

  register(password: string, email: string, dni: string, nombre: string, apellidos: string, pais: number, moneda: number, telefono: string, iban: string): Observable<string> {
    // Cambiar el tipo de retorno a Observable<string>
    // Crear el objeto UsuarioRequest
    const usuarioRequest: UsuarioRequest = {
      email: email,
      password: password,
      dni: dni,
      nombre: nombre,
      apellidos: apellidos,
      pais: pais,
      moneda: moneda,
      telefono: telefono,
      iban: iban,
    };

    // Hacer la solicitud POST a la API
    return this.http
      .post<any>(this.apiUrl + 'auth/signup', usuarioRequest, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response: any) => {
          const datos = response as FirebaseAuthResponse; // Asegúrate de que estás accediendo correctamente al token
          // Verificar que la respuesta contenga el access_token y refresh_token
          if (datos && datos.idToken && datos.refreshToken) {
            // Almacenar el access token y refresh token en el localStorage
            localStorage.setItem('firebaseToken', datos.idToken);
            localStorage.setItem('firebaseRefreshToken', datos.refreshToken);
          } else {
            throw new Error('No valid token in response');
          }
        }),
        mergeMap((response: any) => {
          const datos = response as FirebaseAuthResponse; // Asegúrate de que estás accediendo correctamente al token
          const token = datos.idToken; // Obtén el token

          return from(this.validarTokenYObtenerUid(token)).pipe(
            map((uid) => {
              if (uid) {
                return datos.refreshToken; // Devuelve el refresh token
              } else {
                throw new Error('UID is null'); // Manejar error
              }
            })
          );
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  login(email: string, password: string): Observable<string> {
    const body = {
      email: email,
      password: password,
    };

    return this.http
      .post<any>(this.apiUrl + 'Auth/login', body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response: any) => {
          const datos = response as FirebaseAuthResponse;
          // Verificar que la respuesta contenga el access_token y refresh_token
          if (datos && datos.idToken && datos.refreshToken) {
            // Almacenar el access token y refresh token en el localStorage
            localStorage.setItem('firebaseToken', datos.idToken);
            localStorage.setItem('firebaseRefreshToken', datos.refreshToken);
          } else {
            throw new Error('No valid token in response');
          }
        }),
        mergeMap((response) => {
          const token = localStorage.getItem('firebaseToken');
          return from(this.validarTokenYObtenerUid(token!)).pipe(
            map((uid) => {
              if (uid) {
                return response.refreshToken; // Devuelve el refresh token
              } else {
                throw new Error('UID is null'); // O manejar el error como prefieras
              }
            })
          );
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  resetPassword(email: string): Promise<boolean> {
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }

  // Método para obtener el token del localStorage
  getToken(): string | null {
    return localStorage.getItem('firebaseToken');
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('firebaseRefreshToken');
  }

  // Método para cerrar sesión
  logout() {
    localStorage.removeItem('firebaseToken'); // Eliminar el token del localStorage
    localStorage.removeItem('firebaseRefreshToken');
    this.clearUser();
    this.router.navigate(['/authentication/side-login']); // Redirigir a la página de login
  }

  validarToken(token: string): Observable<boolean> {
    const body = token;

    return this.http
      .post<any>(
        this.apiUrl + 'AuthFirebase/validateToken',
        { token: body },
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(
        map((response) => {
          return response.valid === true;
        })
      );
  }

  validarTokenYObtenerUid(token: string): Promise<boolean> {
    //console.log('Validando token...');
    return lastValueFrom(
      this.http
        .get<any>(`${this.apiUrl}auth/validate/${token}`, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' }),
        })
        .pipe(
          switchMap((response) => {
            const datos = response as FirebaseAuthUsuarioResponse;
            if (datos.usuarioId && datos.usuarioFirebase) {
              return this.setUser(datos.usuarioFirebase).pipe(map(() => true));
            } else {
              this.clearUser();
              return of(false); // Devuelve `false` si no es válido
            }
          }),
          catchError((error) => {
            console.error(error);
            console.log('Error al validar token');
            //this.logout();
            return of(false); // En caso de error, devuelve `false`
          })
        )
    );
  }

  obtenerUsuarioPorUid(uid: string): Observable<Usuario | null> {
    return this.http
      .get<any>(`${this.apiUrl}usuarios/firebase/${uid}`, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' }),
      })
      .pipe(
        map((response) => {
          if (response) {
            return response as Usuario;
          } else {
            return null;
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  private userSubject = new BehaviorSubject<Usuario | null>(null);
  public user$ = this.userSubject.asObservable();

  getUser(): Usuario | null {
    return this.userSubject.getValue();
  }

  setUser(uid: string): Observable<void> {
    return this.obtenerUsuarioPorUid(uid).pipe(
      map((usuario) => {
        this.userSubject.next(usuario);
      }),
      catchError((error) => {
        //this.currentUser = null;
        this.userSubject.next(null);
        return of(); // Devuelve un observable vacío
      })
    );
  }

  clearUser(): void {
    //this.currentUser = null;
    this.userSubject.next(null);
  }

  refreshToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return throwError(() => new Error('No refresh token available'));
    }
    this.isRenewingToken = true;
    // Hacer una solicitud a tu API para obtener un nuevo access token
    return this.http
      .post<any>(
        this.apiUrl + 'auth/renew-token',
        { refreshToken },
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(
        tap((response: any) => {
          //FirebaseAuthRefreshResponse
          const datos = response as FirebaseAuthResponse;
          // Guardar el nuevo token y refresh token en el localStorage
          if (datos && datos.idToken && datos.refreshToken) {
            localStorage.setItem('firebaseToken', datos.idToken);
            localStorage.setItem('firebaseRefreshToken', datos.refreshToken);
            this.isRenewingToken = false;
            this.tokenRenewalSubject.next(true);
          } else {
            this.isRenewingToken = false;
            this.tokenRenewalSubject.next(false);
            throw new Error('No valid token in response');
          }
        }),
        mergeMap((response) => {
          const datos = response as FirebaseAuthResponse; // Asegúrate de que estás accediendo correctamente al token
          const token = datos.idToken;
          return from(this.validarTokenYObtenerUid(token)).pipe(
            map((uid) => {
              if (uid) {
                this.isRenewingToken = false;
                this.tokenRenewalSubject.next(true);
                return datos.refreshToken; // Devuelve el refresh token
              } else {
                this.isRenewingToken = false;
                this.tokenRenewalSubject.next(true);
                throw new Error('UID is null'); // Manejar error
              }
            })
          );
        }),
        catchError((error) => {
          console.error('Error al renovar token:', error);
          // Si el refresh token es inválido o ha expirado, redirigir al login
          localStorage.removeItem('firebaseToken');
          localStorage.removeItem('firebaseRefreshToken');
          this.clearUser();
          this.logout(); // Llama a tu método de logout para manejar la redirección
          this.isRenewingToken = false;
          this.tokenRenewalSubject.next(true);
          return throwError(() => error);
        })
      );
  }

  waitForTokenRenewal(): Promise<boolean> {
    if (!this.isRenewingToken) {
      return Promise.resolve(true);
    }

    return new Promise<boolean>((resolve) => {
      this.tokenRenewalSubject.subscribe(() => {
        resolve(true); // Resuelve cuando se complete la renovación del token
      });
    });
  }

  obtenerUsuariosPost(): Observable<Usuario[]> {
    return this.http
      .post<Usuario[]>(
        this.apiUrl + 'usuarios/',
        {},
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getUsuarioById(id: string): Observable<Usuario> {
    return this.http
      .get<Usuario>(`${this.apiUrl}usuarios/${id}`, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  PutUsuarioById(id: string, usuario: { dni: string; nombre: string; apellidos: string; telefono: string; iban: string }): Observable<Usuario> {
    return this.http
      .put<Usuario>(`${this.apiUrl}usuarios/${id}`, usuario, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  SetCarteraByUsuario(id: string, cartera: { idLFDR: number; nombre: string; tipo: TipoCartera; capitalinicial: number; capitalMaximoBonus: number; fechaRenovacion?: string; diaRenovacion: number; capitalBloqueado: number }): Observable<any> {
    if (cartera.fechaRenovacion === '') {
      delete cartera.fechaRenovacion;
    }
    return this.http
      .post<string>(`${this.apiUrl}usuarios/${id}/carteras`, cartera, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  GetRendimientosByAnyoMes(anyo: number, mes: number): Observable<Resultado[]> {
    const _params = { anyo: anyo.toString(), mes: mes.toString() };
    return this.http
      .get<any>(`${this.apiUrl}resultados`, {
        params: _params,
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  SetRendimiento(anyo: number, mes: number, intervalo: number, rendimiento: number): Observable<any> {
    const body = {
      anyo: anyo,
      mes: mes,
      intervalo: intervalo,
      rendimiento: rendimiento,
    };

    return this.http
      .post<any>(`${this.apiUrl}resultados`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  setResultado(anyo: number, mes: number, intervalo: number, rendimiento: number): Observable<any> {
    const body = {
      anyo: anyo,
      mes: mes,
      intervalo: intervalo,
      rendimiento: rendimiento,
    };

    return this.http
      .post<any>(`${this.apiUrl}resultados/rendimiento-renovacion`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  SetResultadoCartera(anyo: number, mes: number, intervalo: number, carteraId?: string, resultadoId?: string): Observable<any> {
    const body = {
      anyo: anyo,
      mes: mes,
      intervalo: intervalo,
      carteraId: carteraId,
      resultadoId: resultadoId,
    };
    return this.http
      .post<any>(`${this.apiUrl}resultados/rendimiento-cartera`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  UpdateRendimiento(id: string, rendimiento: number): Observable<any> {
    const body = {
      id: id,
      rendimiento: rendimiento,
    };

    console.log(body);

    return this.http
      .put<any>(`${this.apiUrl}resultados`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  SetTokenDevice(id: string, token: string): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiUrl}usuarios/${id}/devices`,
        { token: token },
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  changePassword(nuevaPassword: string, contraseñaActual: string): Observable<any> {
    const currentUser = this.getUser();
    const body = {
      email: currentUser?.email,
      newPassword: nuevaPassword,
      currentPassword: contraseñaActual,
    };

    return this.http
      .post<any>(`${this.apiUrl}auth/change-password`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response: any) => {
          const datos = response as FirebaseAuthResponse;
          // Guardar el nuevo token y refresh token en el localStorage
          if (datos && datos.idToken && datos.refreshToken) {
            localStorage.setItem('firebaseToken', datos.idToken);
            localStorage.setItem('firebaseRefreshToken', datos.refreshToken);
          } else {
            throw new Error('No valid token in response');
          }
        }),
        mergeMap((response) => {
          const datos = response as FirebaseAuthResponse; // Asegúrate de que estás accediendo correctamente al token
          const token = datos.idToken;
          return from(this.validarTokenYObtenerUid(token)).pipe(
            map((uid) => {
              if (uid) {
                return datos.refreshToken; // Devuelve el refresh token
              } else {
                throw new Error('UID is null'); // Manejar error
              }
            })
          );
        }),
        catchError((error) => {
          // Si el refresh token es inválido o ha expirado, redirigir al login
          return throwError(() => error);
        })
      );
  }

  suplantar(uid: string, token: string): Observable<any> {
    const body = {
      uid: uid,
      token: token,
    };

    return this.http
      .post<any>(`${this.apiUrl}Auth/suplantar`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {
          const datos = response as FirebaseAuthResponse;
          if (datos && datos.idToken && datos.refreshToken) {
            const currentUserToken = localStorage.getItem('firebaseToken'); // Guarda el token del usuario actual
            const currentUserRefreshToken = localStorage.getItem('firebaseRefreshToken');

            if (currentUserToken) {
              localStorage.setItem('originalToken', currentUserToken);
            }
            if (currentUserRefreshToken) {
              localStorage.setItem('originalRefreshToken', currentUserRefreshToken);
            }

            localStorage.setItem('firebaseToken', datos.idToken);
            localStorage.setItem('firebaseRefreshToken', datos.refreshToken);

            // this.setUser(uid).subscribe(() => {

            // });
          }
        }),
        mergeMap((response) => {
          const datos = response as FirebaseAuthResponse; // Asegúrate de que estás accediendo correctamente al token
          const token = datos.idToken;
          return from(this.validarTokenYObtenerUid(token)).pipe(
            map((uid) => {
              if (uid) {
                this.suplantacionService.activarSuplantacion();
                return datos.refreshToken; // Devuelve el refresh token
              } else {
                throw new Error('UID is null'); // Manejar error
              }
            })
          );
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  async volverASesionOriginal() {
    const originalToken = localStorage.getItem('originalToken');
    const originalRefreshToken = localStorage.getItem('originalRefreshToken');

    if (originalToken) {
      localStorage.setItem('firebaseToken', originalToken);
    }
    if (originalRefreshToken) {
      localStorage.setItem('firebaseRefreshToken', originalRefreshToken);
    }
    localStorage.removeItem('originalToken'); // Eliminar el token del localStorage
    localStorage.removeItem('originalRefreshToken');

    const isValid = await this.validarTokenYObtenerUid(originalToken!);

    if (isValid) {
      this.suplantacionService.desactivarSuplantacion();
    }

    //location.reload();
  }

  cerrarCartera(carteraId: string): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiUrl}carteras/${carteraId}/close`,
        {},
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  deleteOperacion(carteraId: string, operacionId: string): Observable<any> {
    return this.http
      .delete<any>(`${this.apiUrl}carteras/${carteraId}/operaciones/${operacionId}`, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  operacionCartera(carteraId: string, operacion: string, cantidad: number, nuevaCantidadBloqueo: number, fechaOperacion?: Date): Observable<any> {
    const body: any = {
      cantidad: cantidad,
      nuevaCantidadBloqueo: nuevaCantidadBloqueo,
    };

    if (fechaOperacion) {
      body.fechaOperacion = fechaOperacion;
    } else {
      body.fechaOperacion = new Date();
    }

    console.log(body);

    return this.http
      .post<any>(`${this.apiUrl}carteras/${carteraId}/${operacion}`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  retiroPicoCartera(carteraId: string, cantidad: number, nuevaCantidadBloqueo: number, retiroSuperaMil: boolean, fechaOperacion?: Date): Observable<any> {
    const body: any = {
      cantidad: cantidad,
      nuevaCantidadBloqueo: nuevaCantidadBloqueo,
      retiroSuperaMil: retiroSuperaMil,
    };

    if (fechaOperacion) {
      body.fechaOperacion = fechaOperacion;
    } else {
      body.fechaOperacion = new Date();
    }

    return this.http
      .post<any>(`${this.apiUrl}carteras/${carteraId}/retirar-pico`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  traspasarCartera(carteraId: string, cantidad: number, nuevaCantidadBloqueo: number, carteraDestinoId: string, fechaOperacion?: Date): Observable<any> {
    const body: any = {
      cantidad: cantidad,
      nuevaCantidadBloqueo: nuevaCantidadBloqueo,
      carteraDestinoId: carteraDestinoId,
    };

    if (fechaOperacion) {
      body.fechaOperacion = fechaOperacion;
    } else {
      body.fechaOperacion = new Date();
    }

    return this.http
      .post<any>(`${this.apiUrl}carteras/${carteraId}/traspasar`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  retiroTraspasoCartera(carteraId: string, carteraDestinoId: string, cantidad: number, nuevaCantidadBloqueo: number, cantidadTraspaso: number, EsRetiroPersonalizado: boolean, fechaOperacion?: Date): Observable<any> {
    const body: any = {
      carteraDestinoId: carteraDestinoId,
      cantidad: cantidad,
      nuevaCantidadBloqueo: nuevaCantidadBloqueo,
      cantidadTraspaso: cantidadTraspaso,
      esRetiroPersonalizado: EsRetiroPersonalizado,
    };

    if (fechaOperacion) {
      body.fechaOperacion = fechaOperacion;
    } else {
      body.fechaOperacion = new Date();
    }

    return this.http
      .post<any>(`${this.apiUrl}carteras/${carteraId}/retiro-traspaso`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getCarterasByAnyoMes(anyo: number, mes: number): Observable<Carteras[]> {
    return this.http
      .get<Carteras[]>(`${this.apiUrl}carteras/carterasByAnyoMes`, {
        params: { anyo: anyo.toString(), mes: mes.toString() },
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getCarterasConResultado(anyo: number, mes: number, dia: number): Observable<Carteras[]> {
    return this.http
      .get<Carteras[]>(`${this.apiUrl}carteras/con-resultado`, {
        params: { anyo: anyo.toString(), mes: mes.toString(), dia: dia.toString() },
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getCarterasConFechaRenovacion(anyo: number, mes: number, dia: number): Observable<Carteras[]> {
    return this.http
      .get<Carteras[]>(`${this.apiUrl}carteras/con-fecha-renovacion`, {
        params: { anyo: anyo.toString(), mes: mes.toString(), dia: dia.toString() },
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  actualizarOperacionCartera(carteraId: string, operacionId: string, cantidad: number, nuevaCantidadBloqueo: number): Observable<any> {
    const body = {
      cantidad: cantidad,
      nuevaCantidadBloqueo: nuevaCantidadBloqueo,
    };

    return this.http
      .put<any>(`${this.apiUrl}carteras/${carteraId}/operaciones/${operacionId}`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  actualizarOperacionCarteraRetiroTraspaso(carteraId: string, operacionId: string, cantidad: number, nuevaCantidadBloqueo: number, cantidadTraspaso: number): Observable<any> {
    const body = {
      cantidad: cantidad,
      nuevaCantidadBloqueo: nuevaCantidadBloqueo,
      cantidadTraspaso: cantidadTraspaso,
    };

    return this.http
      .put<any>(`${this.apiUrl}carteras/${carteraId}/operaciones/${operacionId}/retiro-traspaso`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  actualizarOperacionRealizada(carteraId: string, operacionId: string, realizada: boolean): Observable<any> {
    const body = {
      realizada: realizada,
    };

    return this.http
      .put<any>(`${this.apiUrl}carteras/${carteraId}/operaciones/${operacionId}/realizada`, body, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  enviarNotificacionRenovacion(anyo: number, mes: number, dia: number): Observable<any> {
    const params = { anyo: anyo.toString(), mes: mes.toString(), dia: dia.toString() };
    return this.http
      .post<any>(
        `${this.apiUrl}admin/send-notification-to-all`,
        {},
        {
          params: params,
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .pipe(
        tap((response) => {}),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
