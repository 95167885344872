export interface AppSettings {
  dir: 'ltr' | 'rtl';
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: string;
  language: string;
  cardBorder: boolean;
  navPos: 'side' | 'top';
  colors: { [key: string]: string };
}

export const defaults: AppSettings = {
  dir: 'ltr',
  theme: 'light',
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: true,
  horizontal: false,
  cardBorder: false,
  activeTheme: 'blue_theme',
  language: 'es-Es',
  navPos: 'side',
  colors: {
    primary: '#5d87ff',
    secondary: '#49beff',
  },
};

// colors.ts
export const Colors = {
  primary: '#5d87ff',
  secondary: '#49beff',
  // otros colores
  // primary: '#ca942b',
  // secondary: '#217c86',
};
