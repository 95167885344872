import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UsuarioService } from '../services/usuario.service';
import { SuplantacionService } from '../services/suplantacion.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private authService = inject(UsuarioService);
  private suplantacionService = inject(SuplantacionService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let token = this.authService.getToken();

    // Suscríbete al servicio de suplantación para obtener el estado
    this.suplantacionService.suplantando$.subscribe((isSuplantando) => {
      if (!isSuplantando) {
        if (localStorage.getItem('originalToken') && localStorage.getItem('originalRefreshToken') && localStorage.getItem('firebaseToken') && localStorage.getItem('firebaseRefreshToken')) {
          token = localStorage.getItem('originalToken');
          this.authService.volverASesionOriginal();
        }
      }
    });

    // Si no hay token, redirige al usuario
    if (!token) {
      this.redirectToLogin();
      return false;
    }

    // Espera a que el token sea validado
    try {
      const isValid = await this.authService.validarTokenYObtenerUid(token);
      if (!isValid) {
        throw new Error('Token no válido o expirado');
      }

      const user = this.authService.getUser();
      const requiredRole = route.data['requiredRole'];
      const needRenovacion = route.data['needRenovacion'];

      if (needRenovacion && user) {
        const today = new Date();
        const _TieneRenovacion = user.carteras.some((cartera) => {
          const fechaRenovacion = new Date(cartera.fechaRenovacion.fecha);
          const fechaRenovacionMinusFiveDays = new Date(fechaRenovacion);
          fechaRenovacionMinusFiveDays.setDate(fechaRenovacionMinusFiveDays.getDate() - 5);
          return fechaRenovacionMinusFiveDays <= today;
        });

        //TODO: Implementar la redirección
        // console.log('Tiene renovación: ', _TieneRenovacion);
        // if (!_TieneRenovacion) {
        //   this.router.navigate(['/dashboard']);
        //   return false;
        // }
      }

      if (requiredRole && user) {
        const hasRole = user.roles.map((role) => role.rol).some((rol) => requiredRole.includes(rol));

        if (!hasRole) {
          this.router.navigate(['/dashboard']);
          return false;
        }
      }

      return true;
    } catch (error) {
      this.authService.logout();
      this.redirectToLogin();
      return false;
    }
  }

  private redirectToLogin() {
    if (this.router.url.startsWith('/authentication/boxed-register')) {
      this.router.navigate(['/authentication/boxed-register']);
    } else if (this.router.url.startsWith('/authentication/side-login')) {
      this.router.navigate(['/authentication/side-login']);
    } else if (this.router.url.startsWith('/authentication/boxed-forgot-pwd')) {
      this.router.navigate(['/authentication/boxed-forgot-pwd']);
    } else {
      this.router.navigate(['/authentication/side-login']);
    }
  }
}
