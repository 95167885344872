import { Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'starter',
        loadChildren: () => import('./pages/pages.routes').then((m) => m.PagesRoutes),
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./pages/dashboards/dashboards.routes').then((m) => m.DashboardsRoutes),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.routes').then((m) => m.DashboardRoutes),
      },
      {
        path: 'renovacion',
        loadChildren: () => import('./pages/retiro-ingreso/retiro-ingreso.routes').then((m) => m.RetiroIngresoRoutes),
        canActivate: [AuthGuard],
        data: { needRenovacion: true },
      },
      {
        path: 'preguntas',
        loadChildren: () => import('./pages/preguntas-frequentes/preguntas-frequentes.routes').then((m) => m.PreguntasFrequentesRoutes),
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./pages/cartera/cartera.routes').then((m) => m.CarterasRoutes),
        canActivate: [AuthGuard],
        data: { requiredRole: [1, 2] },
      },
      {
        path: 'admin/gestionrenovaciones',
        loadChildren: () => import('./pages/gestion-renovacion/gestion-renovacion.routes').then((m) => m.GestionRenovacionRoutes),
        canActivate: [AuthGuard],
        data: { requiredRole: [1, 2] },
      },
      {
        path: 'admin/gestionrendimientos',
        loadChildren: () => import('./pages/gestion-rendimientos/gestion-rendimientos.routes').then((m) => m.GestionRendimientosRoutes),
        canActivate: [AuthGuard],
        data: { requiredRole: [1] },
      },
      {
        path: 'perfil',
        loadChildren: () => import('./pages/perfil/perfil.routes').then((m) => m.PerfilRoutes),
      },
      {
        path: 'contacto',
        loadChildren: () => import('./pages/contacto-agente/contacto-agente.routes').then((m) => m.ContactoAgenteRoutes),
      },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./pages/authentication/authentication.routes').then((m) => m.AuthenticationRoutes),
      },
      {
        path: 'landingpage',
        loadChildren: () => import('./pages/pages.routes').then((m) => m.PagesRoutes),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
];
