<div class="customizerNav">
  <ng-scrollbar class="position-relative" style="height: calc(100vh - 50px)">
    <div class="p-24">
      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16">Opción tema</h6>
      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.theme" (change)="setDark()" class="customizer-button-group gap-16" [hideSingleSelectionIndicator]="hideSingleSelectionIndicator()">
        <mat-button-toggle [value]="'light'">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="sun-high" class="d-flex m-r-8 fill-icon"></i-tabler>
            Light
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="'dark'">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="moon" class="d-flex m-r-8 fill-icon"></i-tabler>
            Dark
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div>
        <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">Colores tema</h6>
        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.activeTheme" (change)="setColor()" class="customizer-button-group theme-colors two-row" [hideSingleSelectionIndicator]="hideSingleSelectionIndicator()">
          <mat-button-toggle [value]="'blue_theme'">
            <span class="theme-circle blue_theme" matTooltip="blue_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'aqua_theme'">
            <span class="theme-circle aqua_theme" matTooltip="aqua_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'purple_theme'">
            <span class="theme-circle purple_theme" matTooltip="purple_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>

          <mat-button-toggle [value]="'green_theme'">
            <span class="theme-circle green_theme" matTooltip="green_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'cyan_theme'">
            <span class="theme-circle cyan_theme" matTooltip="cyan_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'orange_theme'">
            <span class="theme-circle orange_theme" matTooltip="orange_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'ecdi_theme'">
            <span class="theme-circle ecdi_theme" matTooltip="ecdi_theme" matTooltipClass="text-uppercase" matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      @if(!options.horizontal){
      <div>
        <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">Tipo Sidebar</h6>

        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.sidenavCollapsed" (change)="setSidebar()" class="customizer-button-group gap-16" [hideSingleSelectionIndicator]="hideSingleSelectionIndicator()">
          <mat-button-toggle [value]="false">
            <div class="d-flex align-items-center f-w-500">
              <i-tabler name="layout-sidebar-right" class="d-flex m-r-8 fill-icon"></i-tabler>
              Full
            </div>
          </mat-button-toggle>
          <mat-button-toggle [value]="true">
            <div class="d-flex align-items-center f-w-500">
              <i-tabler name="layout-sidebar" class="d-flex m-r-8 fill-icon"></i-tabler>
              Minisidebar
            </div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      }

      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">Cards con</h6>

      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.cardBorder" class="customizer-button-group gap-16" [hideSingleSelectionIndicator]="hideSingleSelectionIndicator()">
        <mat-button-toggle [value]="false">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="shadow" class="d-flex m-r-8 fill-icon"></i-tabler>
            Shadow
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="true">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="shadow-off" class="d-flex m-r-8 fill-icon"></i-tabler>
            Border
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">Opción Container</h6>

      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.boxed" class="customizer-button-group gap-16" [hideSingleSelectionIndicator]="hideSingleSelectionIndicator()">
        <mat-button-toggle [value]="false">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="layout-navbar" class="d-flex m-r-8 fill-icon"></i-tabler>
            Full
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="true">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="layout-sidebar" class="d-flex m-r-8 fill-icon"></i-tabler>
            Boxed
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-scrollbar>
</div>
