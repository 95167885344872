
import { Component } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  template: `
    <div class="branding">
      <!-- @if(options.theme === 'light') {
      <a href="/">
        <img
          src="./assets/images/logos/Logo-lfdr-horizontal.svg"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      } @if(options.theme === 'dark') {
      <a href="/">
        <img
          src="./assets/images/logos/light-logo.svg"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      } -->
      @if(options.sidenavCollapsed === true) {
        <a href="/">
        <img
          src="./assets/images/logos/ECI_SVGNegro_Isotipo_cropped.svg"
          class="align-middle m-2"
          alt="logo"
          style="width: 45px; height: 45px;"
        />
      </a>
      }@if(options.sidenavCollapsed === false) {
      <a href="/">
        <img
          src="./assets/images/logos/ECI_SVGNegro_Inversor.svg"
          class="align-middle m-2"
          alt="logo"
          style="width: 220px; height: 47px;"
        />
      </a>
      }
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();
  constructor(private settings: CoreService) {}
}
