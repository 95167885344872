import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { UsuarioService } from '../services/usuario.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: UsuarioService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      const cloned = req.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
      return next.handle(cloned).pipe(
        catchError((error) => {
          // Si el servidor devuelve un 401 Unauthorized, intentar refrescar el token
          if (error.status === 401) {
            console.error('Error 401: Unauthorized', error);
            return from(this.authService.refreshToken()).pipe(
              switchMap(() => {
                // Obtener el nuevo token
                const newAccessToken = this.authService.getToken();
                if (!newAccessToken) {
                  this.authService.logout();
                  return throwError(() => error); // Evitar el bucle redirigiendo o deteniendo el flujo
                }
                const modifiedUrl = req.url.replace(/(validate\/)[^/]+/, `$1${newAccessToken}`);
                const newBodyWithToken = { ...req.body, Token: newAccessToken };
                // Clonar la petición original con el nuevo token
                const newClonedRequest = req.clone({
                  setHeaders: {
                    Authorization: `${newAccessToken}`,
                  },
                  url: modifiedUrl,
                  body: newBodyWithToken,
                });
                // Reenviar la petición con el nuevo token
                return next.handle(newClonedRequest);
              }),
              catchError((refreshError) => {
                // Si falla el refresco del token, redirigir al login o manejar el error
                console.error('Token refresh failed', refreshError);
                this.authService.logout();
                return throwError(() => refreshError);
              })
            );
          }

          // Si no es un error 401, continuar con el error original
          return throwError(() => error);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
