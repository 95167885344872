import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root', // Esto asegura que el servicio esté disponible en toda la aplicación
})
export class SuplantacionService {
  private suplantandoSubject = new BehaviorSubject<boolean>(false);
  public suplantando$ = this.suplantandoSubject.asObservable();

  activarSuplantacion() {
    this.suplantandoSubject.next(true);
  }

  desactivarSuplantacion() {
    this.suplantandoSubject.next(false);
  }
}
