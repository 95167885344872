import { Component, Output, EventEmitter, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/pages/dashboard/datos';
import { SuplantacionService } from 'src/app/services/suplantacion.service';
import Swal from 'sweetalert2';

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  public isSuplantando = false;

  private _Usuario: Usuario;
  private _TieneRenovacion: boolean = false;
  _nCarterasRenovacion: number = 0;
  NombreCompleto: string = '';
  Perfil: string = 'Inversor';
  Email: string = '';

  showFiller = false;

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  constructor(private vsidenav: CoreService, public dialog: MatDialog, private translate: TranslateService, private usuarioService: UsuarioService, private suplantacionService: SuplantacionService, private router: Router, private cdr: ChangeDetectorRef) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.suplantacionService.suplantando$.subscribe((isSuplantando) => {
      this.isSuplantando = isSuplantando;
      this.cdr.detectChanges();
    });
  }

  ngAfterContentInit() {
    const user = this.usuarioService.getUser();
    if (user) {
      this._Usuario = user;
      this.NombreCompleto = `${user.nombre} ${user.apellidos}`;
      this.Email = user.email;
      const roles = user.roles.map((role) => role.rol);
      const highestRole = Math.min(...roles);
      switch (highestRole) {
        case 1:
          this.Perfil = 'Admin';
          break;
        case 2:
          this.Perfil = 'Gestor';
          break;
        case 3:
          this.Perfil = 'Inversor';
          break;
        default:
          this.Perfil = 'Unknown';
      }

      const today = new Date();
      const fiveDaysFromNow = new Date(today);
      fiveDaysFromNow.setDate(today.getDate() + 5);

      var carterasRenovacion = user.carteras.filter((cartera) => {
        const fechaRenovacion = new Date(cartera.fechaRenovacion.fecha);
        return fechaRenovacion <= fiveDaysFromNow;
      });

      this.notifications = carterasRenovacion.map((cartera, index) => ({
        id: index + 1,
        img: '/assets/images/profile/vector.png',
        title: `Renovación de ${cartera.nombre} Abierta!`,
        subtitle: 'No olvide elegir una opción',
      }));

      this._nCarterasRenovacion = carterasRenovacion.length;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  navigateTo() {
    this.router.navigate(['/renovacion']);
  }

  volverASesionOriginal() {
    Swal.fire({
      allowOutsideClick: true,
      icon: 'info',
      text: 'Espere, por favor...',
    });
    Swal.showLoading();

    this.usuarioService.volverASesionOriginal().then(() => {
      Swal.fire({
        title: 'Has vuelto a tu sesión!',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        this.router.navigate(['/dashboard']);
        Swal.close();
      });
    });
  }

  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/vector.png',
      title: 'Renovación C. Corto Abierta!',
      subtitle: 'No olvide elegir una opción',
    },
    {
      id: 2,
      img: '/assets/images/profile/vector.png',
      title: 'Renovación C. Largo Abierta!',
      subtitle: 'No olvide elegir una opción',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'Mi Perfil',
      subtitle: 'Configuración Cuenta',
      link: '/perfil',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-master-card-2.svg',
      title: 'Mantenimiento Cartera',
      subtitle: 'Ver Mantenimiento',
      link: '/perfil',
    },
  ];

  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [RouterModule, MaterialModule, TablerIconsModule, FormsModule],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
}
