import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from './environments/environment';
import { UsuarioService } from './services/usuario.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Modernize Angular Admin Tempplate';

  constructor(private router: Router, private authService: UsuarioService, private dateAdapter: DateAdapter<Date>) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        const token = this.authService.getToken();

        // Si ya estamos en la ruta de autenticación, no hacemos nada
        if (event.url.startsWith('/authentication')) {
          if (token) {
            try {
              // Esperar la renovación del token si está en proceso
              //await this.authService.waitForTokenRenewal();
              const isValid = await this.authService.validarTokenYObtenerUid(token);
              if (isValid) {
                // Si el token es válido, cerrar sesión
                console.error('Token inválido');
                this.authService.logout();
              }
            } catch (error) {
              console.error('Error al validar el token:', error);
              this.router.navigate(['/authentication/side-login']);
            }
          }
          return;
        }

        // Verificar si el token existe
        if (token) {
          try {
            // Esperar la renovación del token si está en proceso
            //await this.authService.waitForTokenRenewal();

            const isValid = true; //await this.authService.validarTokenYObtenerUid(token);
            if (!isValid) {
              console.error('Token inválido');
              this.router.navigate(['/authentication/side-login']);
            } else {
              this.listenForMessages();
              this.requestNotificationPermission();
              this.subscribeToNotifications();
            }
          } catch (error) {
            console.error('Error al validar el token:', error);
            this.router.navigate(['/authentication/side-login']);
          }
        } else {
          // Si no hay token, redirigir al login
          console.error('No hay token de autenticación');
          this.router.navigate(['/authentication/side-login']);
        }
      }
    });
  }

  ngOnInit() {
    this.dateAdapter.setLocale('es-Es');
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  // En tu componente de Angular
  requestNotificationPermission() {
    if (Notification.permission === 'granted') {
      //console.log('Notification permission already granted.');
      this.getTokenAndSave();
    } else if (Notification.permission === 'denied') {
      console.error('Notification permission was denied.');
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.getTokenAndSave();
        } else {
        }
      });
    }
  }

  getTokenAndSave() {
    const messaging = getMessaging();
    getToken(messaging)
      .then((token) => {
        if (token) {
          const user = this.authService.getUser();
          if (user && user.id && user.id.value) {
            var idUsuario = user.id.value;
            this.authService.SetTokenDevice(idUsuario, token).subscribe({
              next: (response) => {
                //console.log('Token guardado en el servidor:', response);
              },
              error: (error) => {
                //console.error('Error al guardar el token en el servidor:', error);
              },
            });
          } else {
            //console.error('User or user ID is null');
          }
        }
      })
      .catch((error) => {
        console.error('Error retrieving FCM token:', error);
      });
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, {
      vapidKey: 'BM8S6lYd2vOifxm90anoM7qbrbIOm2l2xbU-9p3dw5V1O4yE0BFmKb5JEszMtSUwbsJL02QNd1jCINrNJSbkTFI',
    })
      .then((currentToken) => {
        if (currentToken) {
          //console.log('Got FCM token:', currentToken);
          //this.saveToken(currentToken); // Guardar el token
        } else {
          //console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        //console.log('An error occurred while retrieving token. ', err);
      });
  }

  listenForMessages() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      //console.log('Message received. ', payload);
      // Aquí puedes manejar la notificación cuando la app está en primer plano
    });
  }

  // Suscribirse a las notificaciones
  subscribeToNotifications() {
    (navigator as any).serviceWorker.ready
      .then((registration: any) => {
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: 'BM8S6lYd2vOifxm90anoM7qbrbIOm2l2xbU-9p3dw5V1O4yE0BFmKb5JEszMtSUwbsJL02QNd1jCINrNJSbkTFI', // Reemplaza con tu clave pública VAPID
        });
      })
      .then((subscription: any) => {
        //console.log('User is subscribed:', subscription);
      })
      .catch((err: any) => {
        //console.log('Failed to subscribe the user: ', err);
      });
  }
}
